import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import loadable from '@loadable/component';
import { isMobile, isIE } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import { userType, application } from 'core/config';
import theme from 'core/theme';

import GlobalStyle from 'styles/global';

const RenderApp = loadable(() => {
  if (isMobile || isIE) return import('apps/notSupported.app');
  if (!userType) return import('apps/signIn.app');
  return import('apps/main.app');
});

export default () => (
  <ThemeProvider theme={theme[application]}>
    <GlobalStyle/>
    <BrowserRouter basename={`/${window.location.pathname.substring(1, 3)}`}>
      <RenderApp />
    </BrowserRouter>
  </ThemeProvider>
);
