export const {
  application,
  base_url: baseUrl,
  core_url: coreUrl,
  current_user: {
    avatar_url: avatarUrl,
    email,
    full_name: fullName,
    phone: phoneNumber,
    user_type: userType,
    recommended_jobs: recommendedJobs
  },
  routes: {
    direct_uploads_path: directUploadsPath,
    login_path: loginPath,
    logout_path: logoutPath
  }
} = JSON.parse(document.getElementById('root').dataset.params);

export const maxFileSize = 2;

export const csrfToken = document.querySelector('meta[name="csrf-token"]') && document.querySelector('meta[name="csrf-token"]').getAttribute('content');
