// @flow

export const
  fontDefault        = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontHeading        = 'SFProDisplay',
  fontText           = 'InterUI',
  fontIcon           = 'Icon Font',

  defaultFontSize    = '16px',
  defaultFontSizeSm  = '14px',

  textSizes = {
    xs:              defaultFontSize,
    sm:              defaultFontSize,
    md:              defaultFontSize,
    lg:              defaultFontSize,
    xl:              defaultFontSize
  },

  zindexs = {
    addNewButton:     900,
    dropdown:         1000,
    popover:          1010,
    sticky:           1020,
    fixed:            1030,
    modalBackdrop:    1040,
    modal:            1050,
    tooltip:          1070,
    toaster:          1080
  },

  gridBreakpoints = {
    xs:               0,
    sm:              540,
    md:              720,
    lg:              960,
    xl:              1210,
    xxl:             1360
  },

  containerMaxWidths = {
    sm:              540,
    md:              720,
    lg:              960,
    xl:              1210,
    xxl:             1360
  },

  breakpoints = {
    sm:               576,
    md:               768,
    lg:               992,
    xl:               1260,
    xxl:              1440
  },

  boxShadow           = '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
  customGutterSize    = 20,
  baseTransition      = '.35s',
  tabNavsHeight       = 62,
  navigationHeight    = '80px',
  asideWidth          = 336;
