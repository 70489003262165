import { css } from 'styled-components';

// interUI
import interUIRegularWoff from 'static/fonts/interUI/regular.woff';
import interUIRegularWoff2 from 'static/fonts/interUI/regular.woff2';
import interUIMediumWoff from 'static/fonts/interUI/medium.woff';
import interUIMediumWoff2 from 'static/fonts/interUI/medium.woff2';
import interUIBoldWoff from 'static/fonts/interUI/bold.woff';
import interUIBoldWoff2 from 'static/fonts/interUI/bold.woff2';

// SFProDisplay
import semiboldWoff from 'static/fonts/SFProDisplay/semibold.woff';
import semiboldWoff2 from 'static/fonts/SFProDisplay/semibold.woff2';

// CircularSTD
import bookWoff from 'static/fonts/circular-std/book.woff';
import bookWoff2 from 'static/fonts/circular-std/book.woff2';
import mediumWoff from 'static/fonts/circular-std/medium.woff';
import mediumWoff2 from 'static/fonts/circular-std/medium.woff2';
import boldWoff from 'static/fonts/circular-std/bold.woff';
import boldWoff2 from 'static/fonts/circular-std/bold.woff2';

// Icons
import iconEot from 'static/fonts/icon/font.eot';
import iconTtf from 'static/fonts/icon/font.ttf';
import iconWoff from 'static/fonts/icon/font.woff';
import iconSvg from 'static/fonts/icon/font.svg';

import { font } from './mixins';

export default css`
  ${font({ woff: interUIRegularWoff, woff2: interUIRegularWoff2 },  'InterUI', 400)}
  ${font({ woff: interUIMediumWoff,  woff2: interUIMediumWoff2 },   'InterUI', 500)}
  ${font({ woff: interUIBoldWoff,    woff2: interUIBoldWoff2 },     'InterUI', 700)}
  
  ${font({ woff: semiboldWoff, woff2: semiboldWoff2 }, 'SFProDisplay', 600)}
  
  ${font({ woff: bookWoff,    woff2: bookWoff2 },   'CircularStd', 400)}
  ${font({ woff: mediumWoff,  woff2: mediumWoff2 }, 'CircularStd', 500)}
  ${font({ woff: boldWoff,    woff2: boldWoff2 },   'CircularStd', 600)}
  
  ${font({ woff: iconWoff, eot: iconEot, ttf: iconTtf, svg: iconSvg }, 'Icon Font')}
`;
