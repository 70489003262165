// @flow

export const
  // GENERAL
  gainsboro         = '#dadada',
  blueGrey          = '#8f959e',
  slateGrey         = '#5c6675',
  dark              = '#2e3646',
  yellowOrange      = '#ffb018',
  tangaroa          = '#ffdaba',
  solitude          = '#fafbfb',
  solitudeAccent    = '#dddee1',
  redPink           = '#ff1e70',
  brightGrey        = '#74757c',
  neonRed           = '#ff1330',
  red               = '#e4544f',

  // BAAM
  flamingo          = '#f1582e',
  flamingoLight     = '#fff9f3',

  // TALENDO
  deepSkyBlue       = '#0c75ff',
  deepSkyBlueLight  = '#f9fbff'
;
