import {
  flamingo,
  flamingoLight,
  deepSkyBlue,
  deepSkyBlueLight
} from 'styles/colors';

const theme = {
  baam: {
    color: {
      primary: flamingo,
      primaryLight: flamingoLight
    },
    font: {
      heading: 'SFProDisplay, Arial, sans-serif',
      text: 'InterUI, sans-serif'
    },
    btn: {
      default: {
        padding: '10px 26px',
        borderRadius: '26px'
      },
      sm: {
        borderRadius: '13px'
      },
      xs: {
        borderRadius: '13px'
      }
    },
    input: {
      borderRadius: '12px'
    },
    card: {
      inlineBgHover: '#faf5f1'
    },
    width: {
      layout: '1000px',
      features: '180px'
    }
  },
  talendo: {
    color: {
      primary: deepSkyBlue,
      primaryLight: deepSkyBlueLight
    },
    font: {
      heading: 'CircularStd, Arial, sans-serif',
      text: 'CircularStd, sans-serif'
    },
    btn: {
      default: {
        padding: '10px 16px',
        borderRadius: '6px'
      },
      sm: {
        borderRadius: '4px'
      },
      xs: {
        borderRadius: '4px'
      }
    },
    input: {
      borderRadius: '4px'
    },
    card: {},
    width: {
      layout: '1200px',
      features: '385px'
    }
  }
};

export default theme;
