// @flow
// TODO: export css classes in appropriate components

import { css } from 'styled-components';
import { rgba } from 'polished';
import { breakpoint } from './mixins';
import { dark, blueGrey } from './colors';

export default css`
  h1, h2, h3, h4, h5, h6 {
    display: block;
    margin: 0 0 12px;
    padding: 0;
    color: ${dark};
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: 600;
    line-height: 1.2em;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: ${dark};
      transition: color .3s ease-in-out;
    }
  }
  h1 {
    font-size: 32px;
    margin-bottom: 20px;
    ${breakpoint.md`font-size: 56px;`}
  }
  h2 {
    font-size: 26px;
    ${breakpoint.md`font-size: 32px;`}
  }
  h3 {
    font-size: 22px;
    ${breakpoint.md`font-size: 24px;`}
  }
  h4 {
    font-size: 18px;
    ${breakpoint.md`font-size: 22px;`}
  }
  h5 {
    font-size: 16px;
    ${breakpoint.md`font-size: 20px;`}
  }
  h6 {
    font-size: 14px;
    ${breakpoint.md`font-size: 16px;`}
  }
  b {
    font-weight: 700;
  }
  strong {
    font-weight: normal;
  }
  blockquote {
    padding: 0 0 0 8px;
    margin: 0 0 24px;
    color: ${({ theme }) => theme.color.primary};
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    border-left: 3px solid ${({ theme }) => theme.color.primary};
    ${breakpoint.md`
      padding-left: 21px;
      font-size: 16px;
      line-height: 1.75;
    `}
    &:last-child {
      margin-bottom: 0
    }
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    cursor: pointer;
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: ${({ theme }) => rgba(theme.color.primary, .8)};
    }
    &.link_dark {
      color: ${blueGrey};
      &:hover,
      &:hover:before {
        color: ${({ theme }) => theme.color.primary};
      }
    }
    &.link_icon {
      position: relative;
    }
    &.icon-back:before {
      font-size: 20px;
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      text-align: center;
      height: 24px;
      line-height: 24px;
      margin-top: -4px;
      margin-right: 5px;
    }
  }
  img {
    max-width: 100%;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.list-icons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 30px 0;
      &:last-child {
        margin-bottom: 0
      }
      &:first-child {
        margin-top: 0;
      }
      li {
        width: 48%;
        position: relative;
        margin-bottom: 20px;
        min-height: 30px;
        padding-left: 42px;
        font-size: 16px;
        padding-top: 2px;
        line-height: 1.75;
        color: ${({ theme }) => theme.color.primary};
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          width: 30px;
          height: 30px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
        }
      }
    }
  }
  ol {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
    counter-reset: item;
    &:last-child {
      margin-bottom: 0;
    }
    ol {
      margin: 10px 0;
      padding-left: 20px;
    }
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: counters(item,".") ". ";
        counter-increment: item;
        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
`;
