// @flow

import styled, { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { navigationHeight, customGutterSize, zindexs } from './variables';
import { blueGrey } from './colors';
import fonts from './fonts';
import typography from './typography';

const GlobalStyle = createGlobalStyle`
  ${fonts}
  ${normalize}
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  *:focus {
    outline: none;
  }
  ::-webkit-scrollbar {
    width: 4px;

    &-track {
      background: transparent;
    }

    &-thumb {
      background: transparent;
      border-radius: 2px;

      &:hover {
        background: ${blueGrey};
      }
    }
  }
  html {
    height: 100%;
  }
  body {
    font-family: ${({ theme }) => theme.font.text};
    background-color: #fff;
    font-weight: 400;
    letter-spacing: 0.3px;
    appearance: none;
    height: 100%;
    overflow-x: none;
    overflow-y: scroll;
  }
  button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    appearance: none;
    padding: 0;
    margin: 0;
  }
  ${typography}
  #root {
    display: flex;
    min-height: 100%;
    padding: ${navigationHeight} 0 0;
  }
  .pac-container {
    z-index: ${zindexs.modal + 1};
  }
`;

// TODO: add to components Grid
export const Row = styled.div`
  display: flex;
  ${({ justifyBetween }) => justifyBetween && 'justify-content: space-between;'}
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}
  ${({ column }) => column && 'flex-direction: column;'}
  ${({ wrap }) => wrap && 'flex-wrap: wrap;'}
  ${({ margin }) => margin && `margin: 0 -${customGutterSize/2}px;`}
`;

export default GlobalStyle;
