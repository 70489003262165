// @flow

import React from 'react';
import ReactDOM from 'react-dom';

import App from 'apps';

const root = document.getElementById('root');

if (root == null) {
  throw new Error('No root element');
}

ReactDOM.render(
  <App/>,
  root
);
